"use client"

import { defaults, schemas } from "@deltagreen/proteus-database"
import React, { PropsWithChildren } from "react"
import { z } from "zod"

const TenantCommonPageContext = React.createContext<z.infer<
  schemas.tenantPageConfig.TenantPageConfigSchemas[schemas.TenantCommonApplicationPage]
> | null>(null)

export function useTenantCommonConfig(): z.infer<schemas.tenantPageConfig.TenantPageConfigSchemas["COMMON"]> {
  const page = "COMMON"
  const context = React.useContext(TenantCommonPageContext)
  if (!context) {
    return defaults.tenantPageConfigs.tenantPageDefaultConfigs[page]
  }

  const schema = schemas.tenantPageConfig.tenantPageConfigSchemas[page]

  const result = schema.safeParse(context)

  if (!result.success) {
    return defaults.tenantPageConfigs.tenantPageDefaultConfigs[page]
  }

  return result.data
}

export const TenantCommonConfigProvider = <T extends schemas.TenantCommonApplicationPage>({
  children,
  config,
}: PropsWithChildren<{ config: z.infer<schemas.tenantPageConfig.TenantPageConfigSchemas[T]> | null }>) => {
  return <TenantCommonPageContext.Provider value={config}>{children}</TenantCommonPageContext.Provider>
}
