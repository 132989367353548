import { assertUnreachable, Maybe } from "@deltagreen/utils"
import { RedirectType } from "next/navigation"
import { createNavigation } from "next-intl/navigation"
import { defineRouting } from "next-intl/routing"
import { getLocale } from "next-intl/server"

export const DEFAULT_LOCALE = "cs" as const
export const supportedLocales = ["cs", "en", "ro"] as const

export const LOCALE_COOKIE_NAME = "NEXT_LOCALE"
export const LOCALE_HEADER_NAME = "x-next-intl-locale"

export const routing = defineRouting({
  // A list of all locales that are supported
  locales: supportedLocales,

  // Used when no locale matches
  defaultLocale: DEFAULT_LOCALE,

  localeCookie: {
    name: LOCALE_COOKIE_NAME,
  },
})

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
const { Link, redirect, usePathname, useRouter, getPathname } = createNavigation(routing)

const redirectWithCurrentLocale = async (href: string, type?: RedirectType): Promise<never> => {
  const locale = await getLocale()
  const res = redirect(
    {
      href,
      locale,
    },
    type,
  )

  assertUnreachable(res)
}

export function isValidLocale(locale: Maybe<string>) {
  if (!locale) {
    return false
  }
  return (routing.locales as unknown as string[]).includes(locale)
}

export { getPathname, Link, redirect, redirectWithCurrentLocale, usePathname, useRouter }
