export const tenantIds = {
  deltagreen: "TID_DELTA_GREEN",
  deltagreenTest: "TID_DELTA_GREEN_TEST",
  eon: "TID_EON",
  centropol: "TID_CENTROPOL",
  mnd: "TID_MND",
  pre: "TID_PRE",
  lamaEnergy: "TID_LAMA_ENERGY",
  goEnergy: "TID_GO_ENERGY",
  premierEnergy: "TID_PREMIER_ENERGY",
} as const

export type TenantId = (typeof tenantIds)[keyof typeof tenantIds]

export function isTenantId(value: unknown): value is TenantId {
  return typeof value === "string" && Object.values(tenantIds).includes(value as unknown as TenantId)
}

export const CONSUMPTION_PREDICTIONS_DAYS_NEEDED = 15

export const logErrorCodes = {
  modbusError: "MODBUS_ERROR",
  modbusClientError: "MODBUS_CLIENT_ERROR",
  instructionRateLimitReached: "INSTRUCTION_RATE_LIMIT_REACHED",
  victronUnknownTopic: "VICTRON_UNKNOWN_TOPIC",
  collectorValueOutsideBoundary: "COLLECTOR_VALUE_OUTSIDE_BOUNDARY",
  oldReconcilerMessage: "OLD_RECONCILER_MESSAGE",
}

export const ELECTRICITY_PRICES = {
  taxElectricity: 28.3,
  feeElectricityBuy: 350,
  feeElectricitySell: 450,
  systemServices: 170.92,
  distributionFeeFallback: 721.45,
  poze: 495,
} satisfies Record<string, number>

export const ELECTRICITY_VAT = 0.21

export const FLEXIBILITY_PRICE_V2_MODIFIER_BASE = 2000

export const trpcErrorMessages = {
  failedPostalCodeGeocoding: "Failed to geocode postal code.",
}

export const distributorCodes = {
  PRE: "PRE",
  CEZ: "CEZ",
  EGD: "EG.D",
} as const

export type DistributorCode = (typeof distributorCodes)[keyof typeof distributorCodes]

export function isSupportedDistributorCode(distributorCode: unknown): distributorCode is DistributorCode {
  return (
    typeof distributorCode === "string" && Object.values(distributorCodes).includes(distributorCode as DistributorCode)
  )
}

const FALLBACK_DISTRIBUTOR_CODE_SUFFIX = "-MANUAL"

export function createFallbackDistributorCode(distributorCode: DistributorCode) {
  return `${distributorCode}${FALLBACK_DISTRIBUTOR_CODE_SUFFIX}`
}

export function isFallbackDistributorCode(distributorCode: string) {
  return (
    distributorCode.endsWith(FALLBACK_DISTRIBUTOR_CODE_SUFFIX) &&
    isSupportedDistributorCode(
      distributorCode.slice(0, distributorCode.indexOf(FALLBACK_DISTRIBUTOR_CODE_SUFFIX.charAt(0))),
    )
  )
}

export const tariffTypes = {
  HT: "HT",
  LT: "LT",
  HTLT: "HT/LT",
} as const

export type TariffType = (typeof tariffTypes)[keyof typeof tariffTypes]

export const tarriffTypeMap = {
  HT: "HT",
  LT: "LT",
  "HT/LT": "HTLT",
} as const
