import(/* webpackMode: "eager", webpackExports: ["TenantProvider"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/app/[fqdn]/[locale]/TenantProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppServerConfigProvider"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/components/AppServerConfigProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalProvider"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/components/GlobalProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeedbackForm"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/components/layout/feedbackForm/FeedbackForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MaintenanceBarClient"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/components/layout/maintenance/MaintenanceBarClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/components/SessionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TenantCommonConfigProvider"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/components/TenantCommonConfigProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/components/ui/Sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DevtoolsDialog"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/sections/devtools/DevtoolsDialog.tsx");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.2.0_react@19.0.0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.2.0_react@19.0.0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.2.0_react@19.0.0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next-intl@3.25.3_next@15.2.0_react@19.0.0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next-intl@3.25.3_next@15.2.0_react@19.0.0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next-intl@3.25.3_next@15.2.0_react@19.0.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@15.2.0_@babel+core@7.26.9_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@15.2.0_@babel+core@7.26.9_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@19.0.0_react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/font.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"nextFontInter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/nuqs@2.3.2_next@15.2.0_react@19.0.0/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager", webpackExports: ["Maintenance"] */ "/builds/deltagreen/delta-monorepo/packages/reactLibrary/dist/index.js");
