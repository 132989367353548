"use client"

import "./feedbackForm.styles.css"

import { useIsDesktop } from "@deltagreen/react-library"
import { isNotNil } from "@deltagreen/utils"
import { captureException } from "@sentry/nextjs"
import dynamic from "next/dynamic"
import { useMedia } from "react-use"

import { api } from "@/app/trpc"
import { usePathname } from "@/i18n/routing"
import { useIsMounted } from "@/utils/hooks/useIsMounted"
import { SCREENS } from "@/utils/styles"

const Popover = dynamic(
  () =>
    import("@typeform/embed-react").then((module) => ({
      default: module.Popover,
    })),
  {
    ssr: false,
  },
)

const typeformBaseConfig = {
  id: "yPnc967m",
  buttonColor: "#00515f",
  height: 700,
  customIcon: "https://cdn.deltagreen.cz/misc/comment_with_star.png",
}

function useTypeformConfig() {
  const isLargeDesktop = useMedia(`(min-width: ${SCREENS["2xl"]})`, false)
  const isSmallDesktop = useIsDesktop()

  if (!isSmallDesktop) {
    // Mobile menu shows so we need to adjust the button position to be on top so it doesn't overlap
    return {
      ...typeformBaseConfig,
      buttonWidth: 30,
      buttonProps: {
        style: "top: 8px !important; right: 8px !important;",
      },
    }
  }

  if (!isLargeDesktop) {
    return {
      ...typeformBaseConfig,
      height: 500,
    }
  }

  return typeformBaseConfig
}

type Props = {
  userId: string | null
}

function AuthenticatedFeedbackForm(props: { userId: string }) {
  const pathname = usePathname()
  const inverterList = api.inverters.list.useQuery()
  const config = useTypeformConfig()

  if (inverterList.isLoading) {
    return null
  }

  if (inverterList.isError) {
    captureException(inverterList.error)
    return null
  }

  return (
    <Popover
      {...config}
      hidden={{
        url: pathname,
        user_id: props.userId,
        inverters: JSON.stringify(inverterList.data),
      }}
    />
  )
}

function UnauthenticatedFeedbackForm() {
  const pathname = usePathname()
  const config = useTypeformConfig()

  return (
    <Popover
      {...config}
      hidden={{
        url: pathname,
      }}
    />
  )
}

export function FeedbackForm(props: Props) {
  const isMounted = useIsMounted()
  if (!isMounted) {
    return null
  }

  if (isNotNil(props.userId)) {
    return <AuthenticatedFeedbackForm userId={props.userId} />
  } else {
    return <UnauthenticatedFeedbackForm />
  }
}
